<template>
  <div class="mine container">
    <div class="title">{{ $t('app.mine.title') }}</div>
    <div class="sub-title">{{ $t('app.mine.subTitle') }}</div>
    <template v-if="nftTotalPage >= 1">
      <ul class="nft-list">
        <li v-for="(item) in nftPageList" :key="'nft' + item.id">
          <div class="item">
            <div class="id">No.{{ item.id }}</div>
            <div class="name">{{ item.type ? $t('web.crowd.name2') : $t('web.crowd.name1') }}</div>
            <img :src="require(`../../assets/img/angel-${(item.type + 1)}.png`)">
          </div>
          <div class="btn">
            <div class="btn-transf" @click="handleActiveDialog(item)" v-if="!item.isGrounding">{{ $t('app.mine.transfer') }}</div>
            <div class="btn-transf1"  v-else>{{ $t('app.mine.transfer') }}</div>
            <div v-if="!item.type">
              <div class="btn-transf" v-if="item.isGrounding" @click="withdraw1(item.id)">
                {{ $t('app.cooperation.withdraw') }}
              </div>
              <div v-else>
                <div class="btn-transf" @click="pledge1(item.id)" v-if="isAccredit1">
                  {{ $t('app.cooperation.pledge') }}
                </div>
                <div class="btn-transf" @click="accredit1" v-else>
                  {{ $t('app.cooperation.Authorize') }}
                </div>
              </div>
            </div>
            <div v-if="item.type">
              <div class="btn-transf" v-if="item.isGrounding" @click="withdraw2(item.id)">
                {{ $t('app.cooperation.withdraw') }}
              </div>
              <div v-else>
                <div class="btn-transf" @click="pledge2(item.id)" v-if="isAccredit2">
                  {{ $t('app.cooperation.pledge') }}
                </div>
                <div class="btn-transf" @click="accredit2" v-else>
                   {{ $t('app.cooperation.Authorize') }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="pagination" v-if="nftTotalPage > 1">
        <div class="pre" @click="handleNFTPage(-1)">{{ $t('com.pre') }}</div>
        <div class="page"><span>{{ nftPage }}</span> / {{ nftTotalPage }}</div>
        <div class="next" @click="handleNFTPage(1)">{{ $t('com.next') }}</div>
      </div>
    </template>
    <div class="empty" v-else>
      <div class="empty-bg"></div>
      <div class="tips">{{ $t('com.empty') }}</div>
    </div>
    <div class="border team" ref="team">
      <div class="sub-title">{{ $t('app.mine.title') }}</div>
      <div class="info">
        <div class="link">
          <div class="sub-title">{{ $t('app.mine.subTitle1') }}</div>
          <div>{{ url | addressCharacter(15, 6) }} <i @click="handleCopyClick"></i></div>
        </div>
        <!-- <div class="total">
          <div class="sub-title">{{ $t('app.mine.subTitle2') }}</div>
          <div>{{ total | numFilter2 }}</div>
        </div> -->
        <div class="total">
          <div class="sub-title">{{ $t('app.mine.level') }}</div>
          <div>V{{ level }}</div>
        </div>
        <div class="total">
          <div class="sub-title">{{ $t('app.mine.subTitle2') }}</div>
          <div>{{ total | numFilter2 }}</div>
        </div>
      </div>
      <div class="sub-title">{{ $t('app.mine.subTitle3') }}</div>
      <template v-if="totalPage >= 1">
        <ul class="sub-list">
          <li>
            <span>{{ $t('web.team.address') }}</span>
            <span>{{ $t('web.team.sub') }}</span>
          </li>
          <li v-for="item in subPageList" :key="item.address">
            <span>{{ item.address | addressCharacter(6 , 6) }}</span>
            <span>{{ item.power | numFilter6 }}</span>
          </li>
        </ul>
        <div class="pagination" v-if="totalPage > 1">
          <div class="pre" @click="handleExchangePage(-1)">{{ $t('com.pre') }}</div>
          <div class="page"><span>{{ page }}</span> / {{ totalPage }}</div>
          <div class="next" @click="handleExchangePage(1)">{{ $t('com.next') }}</div>
        </div>
      </template>
      <div class="empty" v-else>
        <div class="empty-bg"></div>
        <div class="tips">{{ $t('com.empty') }}</div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="6rem" @closed="handleDialogClose">
      <div class="dialog-content">
        <div class="dialog-title">{{ $t('app.mine.transfer') }}</div>
        <input type="text" v-model="targetAddress" placeholder="address">
        <div class="btn-dialog" @click="handleTrans">{{ $t('app.mine.transfer') }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ethers } from 'ethers'
import Core2 from '../../contracts/Core2.json'
// import Community from '../../contracts/Community.json'
import Bind from '../../contracts/Bind.json'
import ERC721 from '../../contracts/ERC721.json'
import { initSigner } from '../../utlis/index'
import Board from "../../contracts/Board.json"
export default {
  data() {
    return {
      address: '',
      contracts: {},
      referee: '',
      nftList: [],      // NFT列表
      nftPage: 1,
      nftPageSize: 4,

      total: 0,         // 直推总金额
      subList: [],      // 下级地址
      page: 1,          // 当前分页
      pageSize: 3,      // 分页条目数

      targetAddress: '',  // 被转让地址
      dialogVisible: false,
      chooseItem: {},
      level: 0,
      isAccredit1: false,
      isAccredit2: false,
    }
  },
  computed: {
    // 邀请地址
    url() {
      if (!this.address) return '-'
      let url = location.origin + '?referee=' + this.address
      return url
    },
    // 下级地址总页数
    totalPage() {
      return Math.ceil(this.subList.length / this.pageSize)
    },
    // nft总页数
    nftTotalPage() {
      return Math.ceil(this.nftList.length / this.nftPageSize)
    },
    // NFT当前分页列表
    nftPageList() {
      return this.nftList.slice((this.nftPage - 1) * this.nftPageSize, this.nftPage * this.nftPageSize)
    },
    // 下级地址分页列表
    subPageList() {
      return this.subList.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  methods: {
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address

      this.contracts.Core2 = new ethers.Contract(this.$common.Core2, Core2, provider).connect(signer)
      // this.contracts.Community = new ethers.Contract(this.$common.Community, Community, provider).connect(signer)
      this.contracts.Bind = new ethers.Contract(this.$common.Bind, Bind, provider).connect(signer)
      this.contracts.Ambassador = new ethers.Contract(this.$common.Ambassador, ERC721, provider).connect(signer)
      this.contracts.Envoy = new ethers.Contract(this.$common.Envoy, ERC721, provider).connect(signer)
      this.contracts.AmbassadorBoard = new ethers.Contract(this.$common.AmbassadorBoard, Board, provider).connect(signer)
      this.contracts.EnvoyBoard = new ethers.Contract(this.$common.EnvoyBoard, Board, provider).connect(signer)
      window.contracts = this.cFontracts;
      this.getData()
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000);
    },
    async getData() {
      if (!this.address) return false
      // 获取拥有的NFT(0为大使，1为特使)
      this.contracts.Core2.getNFT(0, this.address).then(res1 => {
        let arr = []
        for (const key in res1[0]) {
          arr.push({
            id: Number(res1[0][key]),
            state: res1[1][key],
            isGrounding: res1[2][key],
            type: 0,
          })
        }
        this.contracts.Core2.getNFT(1, this.address).then(res2 => {
          for (const key in res2[0]) {
            arr.push({
              id: Number(res2[0][key]),
              state: res2[1][key],
              isGrounding: res2[2][key],
              type: 1,
            })
          }
          this.nftList = arr
        })
      })
      // // 直推奖励
      // this.contracts.Community.underNumber(this.address).then(res => {
      //   let num = ethers.utils.formatUnits(res, 18)
      //   this.total = num
      // })
      // // 获取下级地址
      // this.contracts.Community.getSubordinateReward(this.address).then(res => {
      //   let arr = []
      //   for (const key in res[0]) {
      //     arr.push({
      //       address: res[0][key],
      //       power: ethers.utils.formatUnits(res[1][key], 18)
      //     })
      //   }
      //   this.subList = arr
      // })
      // // 账号等级
      // this.contracts.Community.level(this.address).then(res => {
      //   this.level = Number(res)
      // })
      this.contracts.Ambassador.isApprovedForAll(this.address, this.$common.AmbassadorBoard).then(res => {
        this.isAccredit1 = res
      })
      this.contracts.Envoy.isApprovedForAll(this.address, this.$common.EnvoyBoard).then(res => {
        this.isAccredit2 = res
      })
    },
    // 切换下级地址分页
    handleExchangePage(num) {
      if (this.totalPage <= 1) {
        return false
      } else if (this.page + num > this.totalPage) {
        this.page = 1
      } else if (this.page + num < 1) {
        this.page = this.totalPage
      } else {
        this.page += num
      }
    },
    // 切换NFT列表分页
    handleNFTPage(num) {
      if (this.nftTotalPage <= 1) {
        return false
      } else if (this.nftPage + num > this.nftTotalPage) {
        this.nftPage = 1
      } else if (this.nftPage + num < 1) {
        this.nftPage = this.nftTotalPage
      } else {
        this.nftPage += num
      }
    },
    // 转让
    handleTrans() {
      if (!this.targetAddress) return false
      let str = ''
      switch (this.chooseItem.type) {
        case 0:
          str = 'Ambassador'
          break;
        case 1:
          str = 'Envoy'
          break
      }
      this.contracts[str].transferFrom(this.address, this.targetAddress, this.chooseItem.id).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('msg.transferSucc'))
        this.getData()
        this.dialogVisible = false
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('msg.transferFailed'))
      });
    },
    // 关闭转让弹窗后初始化数据
    handleDialogClose() {
      this.chooseItem = {}
      this.targetAddress = ''
    },
    // 拉起转让弹窗
    handleActiveDialog(item) {
      this.chooseItem = item
      this.dialogVisible = true
    },
    // 复制邀请链接
    handleCopyClick() {
      let input = document.createElement("input");
      input.value = this.url;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success(this.$t('msg.copySucc'))
    },
    // 授权1
    accredit1() {
      this.contracts.Ambassador.setApprovalForAll(this.$common.AmbassadorBoard, true).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.approveCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.approveSB'))
      });
    },
    // 质押
    pledge1(val) {
      this.contracts.AmbassadorBoard.stake(val).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.pledgeCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.pledgeSB'))
      });
    },
    //撤回
    withdraw1(val){
       this.contracts.AmbassadorBoard.withdraw(val).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.withdrawCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.withdrawSB'))
      });
    },
    // 授权2
    accredit2() {
      this.contracts.Envoy.setApprovalForAll(this.$common.EnvoyBoard, true).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.approveCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.approveSB'))
      });
    },
    // 质押2
    pledge2(val) {
      this.contracts.EnvoyBoard.stake(val).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.pledgeCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.pledgeSB'))
      });
    },
    withdraw2(val){
       this.contracts.EnvoyBoard.withdraw(val).then(async (result) => {
        await result.wait()
        this.$message.success(this.$t('app.cooperation.withdrawCG'))
        this.getData()
      }).catch((err) => {
        console.log(err);
        this.$message.error(this.$t('app.cooperation.withdrawSB'))
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.mine {
  padding: 1.4rem 0.44rem 0.4rem;
  font-size: 00.28rem;

  &::v-deep .el-dialog {
    background-color: rgba(33, 33, 33, 0.7);
    box-sizing: border-box;
    border: 1px solid #45bbf3;
    .el-dialog__header {
      padding: 0;
    }
  }

  .empty {
    font-size: 0.28rem;
    padding: 0.4rem 0;
    .empty-bg {
      width: 2.9rem;
      height: 1.87rem;
      margin: 0 auto;
      background: url("../../assets/img/empty.png") left top / 100% 100%
        no-repeat;
    }
    .tips {
      margin-top: 0.2rem;
      text-align: center;
    }
  }

  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url("../../assets/img/web-title-bg.png") left top / 100% 100%
      no-repeat;
  }
  .sub-title {
    font-size: 0.3rem;
    text-align: center;
    margin-bottom: 0.4rem;
  }
  .nft-list {
    display: grid;
    grid-template-columns: repeat(2, 2.98rem);
    justify-content: space-between;
    grid-gap: 0.6rem;
    margin-bottom: 0.4rem;
    li {
      .item {
        position: relative;
        padding: 0.1rem 0.2rem 0;
        height: 3.9rem;
        text-align: right;
        z-index: 10;
        .name {
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0.25rem;
          font-weight: bold;
          z-index: 2;
        }
        .id {
          position: relative;
          color: #45bbf3;
          font-size: 0.24rem;
          z-index: 2;
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
      }
      .btn {
        display: flex;
        justify-content: space-between;
      }
      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn-transf {
        display: inline-block;
        width: 1.4rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.3rem;
        background-color: rgba(85, 114, 241, 0.8);
        box-sizing: border-box;
        border: 1px solid #45bbf3;
        text-align: center;
        margin-top: 0.25rem;
        position: relative;
        // &::after {
        //   content: ' ';
        //   position: absolute;
        //   left: 0.08rem;
        //   top: 0.08rem;
        //   width: 100%;
        //   height: 100%;
        //   border: 1px solid #B285ED;
        // }
      }
      .btn-transf1 {
        display: inline-block;
        width: 1.4rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.3rem;
        background-color: rgba(189, 189, 189, 0.8);
        box-sizing: border-box;
        border: 1px solid #45bbf3;
        text-align: center;
        margin-top: 0.25rem;
        position: relative;
        // &::after {
        //   content: ' ';
        //   position: absolute;
        //   left: 0.08rem;
        //   top: 0.08rem;
        //   width: 100%;
        //   height: 100%;
        //   border: 1px solid #B285ED;
        // }
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.3rem;
    margin: 0.3rem 0 0.6rem;
    .page {
      width: 2rem;
      text-align: center;
      span {
        color: #45bbf3;
      }
    }
    .pre,
    .next {
      cursor: pointer;
      &:hover {
        color: #45bbf3;
      }
    }
  }

  .team {
    font-size: 0.28rem;
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.3rem;
      .link {
        font-size: 0.28rem;
        div {
          font-size: 0.24rem;
          &:last-child {
            display: flex;
            align-items: center;
            color: #45bbf3;
            i {
              width: 0.38rem;
              height: 0.38rem;
              margin-left: 0.25rem;
              cursor: pointer;
              background: url("../../assets/img/web-copy.png") left top / 100%
                100% no-repeat;
            }
          }
        }
      }
      .total {
        font-size: 0.24rem;
        & > div {
          font-size: 0.24rem;
          &:last-child {
            color: #45bbf3;
            text-align: center;
          }
        }
      }
    }
    .sub-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        height: 0.7rem;
        line-height: 0.7rem;
        font-size: 0.24rem;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        span {
          width: 2.5rem;
          text-align: center;
        }
      }
    }
  }
  .dialog-content {
    font-size: 00.3rem;
    color: #fff;
    .dialog-title {
      color: #45bbf3;
      font-size: 0.36rem;
      margin-bottom: 0.5rem;
      text-align: center;
    }
    input {
      width: 100%;
      // height: 0.84rem;
      padding: 0.3rem 0.2rem;
      font-size: 0.24rem;
      margin-bottom: 0.2rem;
      background-color: rgba(85, 114, 241, 0.5);
      border: 1px solid #45bbf3;
      box-sizing: border-box;
      color: #fff;
    }
    .btn-dialog {
      height: 0.9rem;
      line-height: 0.9rem;
      font-size: 0.28rem;
      background-color: rgba(178, 133, 237, 0.8);
      box-sizing: border-box;
      border: 1px solid #45bbf3;
      text-align: center;
      margin-top: 0.25rem;
      position: relative;
      // &::after {
      //   content: ' ';
      //   position: absolute;
      //   left: 0.08rem;
      //   top: 0.08rem;
      //   width: 100%;
      //   height: 100%;
      //   border: 1px solid #B285ED;
      // }
    }
  }
}
</style>
